import axios from 'axios'

export default {
  getApplicationUsers: async function () {
    const url = '/Users'
    const response = await axios.get(url, { crossdomain: true })
    return response.data
  },
  getUserById: async function (userId) {
    const url = `/Users/${userId}`
    const response = await axios.get(url, { crossdomain: true })
    return response.data
  },
  updateUser: async function (userData) {
    const url = '/Users/updateUser'
    const response = await axios.put(url, userData, { crossdomain: true })
    return response.data
  },
  disableUser: async function (userId) {
    const url = `/Users/disableUser/${userId}`
    const response = await axios.put(url, { crossdomain: true })
    return response.data
  },
  registerUser: async function (userData) {
    const url = '/Users/addUser'
    const response = await axios.post(url, userData, { crossdomain: true })
    return response.data
  },
  getCurrentUser: async function (userData) {
    const url = '/Users/current'
    const response = await axios.post(url, userData, { crossdomain: true })
    return response.data
  },
  getApplicationRoles: async function () {
    const url = '/ApplicationRoles'
    const response = await axios.get(url, { crossdomain: true })
    return response.data
  },
  inviteUser: async function (userId) {
    const url = `Users/invite/${userId}`
    const resposne = await axios.put(url, { crossdomain: true })
    return resposne.data
  },
  getClientIp: async function () {
    const clientIp = window.myIPAddress
    /*
    const apiResponse = await axios.get('https://cors-anywhere.herokuapp.com/https://api.ipify.org?format=json')
    if (apiResponse !== undefined) {
      if (apiResponse !== null) {
        clientIp = apiResponse.data.ip
      }
    }
    */
    /*
    const apiResponse = await axios.get('https://cors-anywhere.herokuapp.com/https://json.geoiplookup.io/')
    if (apiResponse !== undefined) {
      if (apiResponse !== null) {
        clientIp = apiResponse.data.ip
      }
    }
    */
    return clientIp
  },
}
