import axios from 'axios'

export default {
  getAllPartners: async function () {
    const url = '/Partners/'
    const response = await axios.get(url, { crossdomain: true })
    return response.data
  },
  getPartnerById: async function (id) {
    const url = `/Partners/${id}`
    const response = await axios.get(url, { crossdomain: true })
    return response.data
  },
  addNewPartner: async function (partnerData) {
    const url = '/Partners/addPartner'
    const response = await axios.post(url, partnerData, { crossdomain: true })
    return response.data
  },
  updatePartner: async function (partnerData) {
    const url = '/Partners/updatepartner'
    const response = await axios.put(url, partnerData, { crossdomain: true })
    return response.data
  },
  deletePartner: async function (partnerId) {
    const url = `/Partners/delete/${partnerId}`
    const response = await axios.delete(url, { crossdomain: true })
    return response.data
  },
}
