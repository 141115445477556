import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import i18n from '@/i18n'
import '@/sass/overrides.sass'

Vue.use(Vuetify)
/*
const theme = {
  primary: '#E91E63',
  secondary: '#9C27b0',
  accent: '#9C27b0',
  info: '#00CAE3',
}
*/
const theme = {
  primary: '#00867c',
  primary2: '#007299',
  primary1: '#68c8c6',
  secondary: '#68C8C6',
  accent: '#0A3A5F',
  error: '#FF5252',
  info: '#68c8c6',
  info2: '#9AC2C9',
  success: '#4CAF50',
  warning: '#FB8C00',
}

export default new Vuetify({
  lang: {
    t: (key, ...params) => i18n.t(key, params),
  },
  theme: {
    themes: {
      dark: theme,
      light: theme,
    },
  },
  icons: {
    iconfont: 'mdiSvg' || 'faSvg', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  },
})
