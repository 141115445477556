<template>
  <router-view />
</template>

<script>
  export default {
    name: 'App',
  }
</script>

<style lang="sass">
  $inventory-color: #827717

  .v-application .inventorycol
    background-color: rgb(220, 231, 117)
    font-weight: bold
  .v-application .salescommitmentcol
    background-color: rgb(0, 114, 153)
    font-weight: bold
  .v-application .transactioncol
    background-color: rgb(30, 136, 229)
    font-weight: bold
  .v-application .historycol
    background-color: rgb(189, 189, 189)
    font-weight: bold
</style>
