// =========================================================
// * Vuetify Material Dashboard PRO - v2.1.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vuetify-material-dashboard-pro
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import Vue from 'vue'
import vuetify from './plugins/vuetify'
import Vuelidate from 'vuelidate'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/base'
import i18n from './i18n'
import axios from 'axios'
import msal from 'vue-msal'
import config from '@/authentication/config'
import resToken from '@/authentication/resourceToken'
import VueMask from 'v-mask'
import VueMoment from 'vue-moment'
import device from 'vue-device-detector'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import browserDetect from 'vue-browser-detect-plugin'

Vue.config.productionTip = false

Vue.use(msal, {
  auth: {
    clientId: config.clientId,
    tenantId: config.tenant,
    redirectUri: process.env.VUE_APP_REDIRECT_URL,
    postLogoutRedirectUri: process.env.VUE_APP_REDIRECT_LOGOUT_URL,
    requireAuthOnInitialize: false,
    onAuthentication: function (ctx, error, response) {
      // ctx - msal class context, error = null or message, response or null
      // debugger
    },
    onToken: function (ctx, error, response) {
      // ctx - msal class context, error = null or message, response or null
      // debugger
      // if (response.scopes.includes('User.Invite.All') && response.accessToken !== null) {
      // localStorage.setItem('GraphToken', response.accessToken)
      // }
      // if (response.scopes.includes('https://eastmanchem.onmicrosoft.com/cechargebackapi/user_impersonation') && response.accessToken !== null) {
      //  localStorage.setItem('ResourceToken', response.accessToken)
      // }
      // console.log(response)
    },
  },
  graph: {
    callAfterInit: true,
    endpoints: {
      profile: '/me',
      photo: { url: '/me/photo/$value', responseType: 'arraybuffer', force: true },
    },
    onResponse: function (ctx, response) {
      const base64string = btoa(String.fromCharCode.apply(null, new Uint8Array(response.photo)))
      localStorage.setItem('ProfileImage', base64string)
    },
  },
  request: {
    scopes: config.scopes,
  },
  cache: {
    cacheLocation: config.cacheLocation,
    storeAuthStateInCookie: false,
  },
  framework: {
    globalMixin: true,
  },
})
Vue.use(Vuelidate)
Vue.use(VueMask)
Vue.use(VueMoment)
Vue.use(device)
Vue.use(VueReCaptcha, {
  siteKey: process.env.VUE_APP_RECAPTCHA,
  loaderOptions: {
    useRecaptchaNet: true,
  },
})
Vue.use(browserDetect)
axios.defaults.baseURL = process.env.VUE_APP_API_URL
axios.defaults.headers.post['Content-Type'] = process.env.VUE_APP_API_CONTENT_TYPE
axios.interceptors.request.use(
  async request => {
    // console.log('request interceptor ' + request.baseURL + request.url)
    if (request.baseURL === process.env.VUE_APP_API_URL) {
      await setToken(request)
    }
    return request
  },
  error => {
    return Promise.reject(error)
  },
)
axios.interceptors.response.use(
  response => {
    return response
  },
  async error => {
    if (error.response !== undefined) {
      const originalRequest = error.config
      if (error.response.status === 401 &&
        originalRequest.baseURL === process.env.VUE_APP_API_URL &&
        !originalRequest._retry) {
        // console.log('response interceptor ' + originalRequest.baseURL + originalRequest.url)
        originalRequest._retry = true
        localStorage.removeItem('ResourceToken')
        await setToken(originalRequest)
        return axios(originalRequest)
      } else {
        return Promise.reject(error)
      }
    }
  },
)
const setToken = async (request) => {
  const accessToken = await resToken.getResourceToken()
  if (accessToken !== null) {
    request.headers = {
      Authorization: 'Bearer ' + accessToken,
    }
  }
}
new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app')
