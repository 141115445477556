export default {
  isAuthorized: function (currentUser, type) {
    let authorized = false
    if (currentUser != null) {
      switch (type) {
        case 1:
          // add partner
          if (currentUser.roleId === 999) {
            authorized = true
          }
          break
        case 2:
          // add product
          if (currentUser.roleId === 999) {
            authorized = true
          }
          break
        case 3:
          // add product listing - allow for all
          authorized = true
          break
        case 4:
          // add/edit/delete users
          if (currentUser.roleId === 999) {
            authorized = true
          }
          break
        case 5:
          // Allow access to Material Movement
          authorized = true
          break
        case 6:
          // Allow access to Settings Menu
          if (currentUser.roleId === 999) {
            authorized = true
          }
          break
        case 7:
          // Allow access to Master Data Menu
          if (currentUser.roleId === 999) {
            authorized = true
          }
          break
        case 99:
          // Allow access to Admin Menu
          if (currentUser.roleId === 999) {
            authorized = true
          }
          break
        default:
          break
      }
    }
    return authorized
  },
}
