import axios from 'axios'

export default {
  getAllProducts: async function () {
    const url = '/Products/'
    const response = await axios.get(url, { crossdomain: true })
    return response.data
  },
  getProductById: async function (productId) {
    const url = '/Products/' + productId
    const response = await axios.get(url, { crossdomain: true })
    return response.data
  },
  getAllProductLinks: async function () {
    const url = '/Products/links/'
    const response = await axios.get(url, { crossdomain: true })
    return response.data
  },
  getProductLinksByProduct: async function (productId) {
    const url = '/Products/links/product/' + productId
    const response = await axios.get(url, { crossdomain: true })
    return response.data
  },
  getProductLinksByPartner: async function (partnerId) {
    const url = '/Products/links/partner/' + partnerId
    const response = await axios.get(url, { crossdomain: true })
    return response.data
  },
  getProductLinkById: async function (linkId) {
    const url = `/Products/links/${linkId}`
    const response = await axios.get(url, { crossdomain: true })
    return response.data
  },
  getAllProductListings: async function () {
    const url = '/ProductListings/'
    const response = await axios.get(url, { crossdomain: true })
    return response.data
  },
  getProductListingsByProductType: async function (type) {
    const url = '/ProductListings/type/' + type
    const response = await axios.get(url, { crossdomain: true })
    return response.data
  },
  getProductListingsByProduct: async function (productId) {
    const url = '/ProductListings/product/' + productId
    const response = await axios.get(url, { crossdomain: true })
    return response.data
  },
  getProductListingsByPartner: async function (partnerId) {
    const url = '/ProductListings/partner/' + partnerId
    const response = await axios.get(url, { crossdomain: true })
    return response.data
  },
  getProductListingById: async function (listingId) {
    const url = `/ProductListings/${listingId}`
    const response = await axios.get(url, { crossdomain: true })
    return response.data
  },
  getProductListingConversion: async function (listingId, partnerId) {
    const url = `/ProductListings/Conversion/${listingId}/${partnerId}`
    const response = await axios.get(url, { crossdomain: true })
    return response.data
  },
  updateProductListing: async function (listingData) {
    const url = '/ProductListings/updateListing'
    const response = await axios.put(url, listingData, { crossdomain: true })
    return response.data
  },
  addProduct: async function (productData) {
    const request = {
      ProductId: 0,
      ProductType: Number(productData.ProductType),
      ProductName: productData.ProductName,
      Description: productData.Description,
      UnitofMeasure: Number(productData.UnitOfMeasure),
      StatusCode: Number(productData.StatusCode),
    }
    const url = '/Products/addProduct'
    const response = await axios.post(url, request, { crossdomain: true })
    return response
  },
  updateProduct: async function (productData) {
    const url = '/Products/updateProduct'
    const response = await axios.put(url, productData, { crossdomain: true })
    return response
  },
  addProductLink: async function (linkData) {
    const url = '/Products/links/addLink'
    const response = await axios.post(url, linkData, { crossdomain: true })
    return response
  },
  updateProductLink: async function (linkData) {
    const url = '/Products/links/updateLink'
    const response = await axios.put(url, linkData, { crossdomain: true })
    return response
  },
  addProductListing: async function (listingData) {
    const url = '/ProductListings/addListing'
    const requestData = {
      ListingId: 0,
      PartnerId: Number(listingData.PartnerId),
      ProductId: Number(listingData.ProductId),
      ProductAlias: listingData.ProductAlias,
      ProductNumber: listingData.ProductNumber,
      ProductType: Number(listingData.ProductType),
      StatusCode: Number(listingData.StatusCode),
      RContent: Number(listingData.RContent),
      ConversionFactors: listingData.ConversionFactors,
    }
    const response = await axios.post(url, requestData, { crossdomain: true })
    return response
  },
  deleteProduct: async function (productID) {
    const url = `/Products/delete/${productID}`
    const response = await axios.delete(url, { crossdomain: true })
    return response
  },
  deleteProductLink: async function (linkID) {
    const url = `/Products/links/delete/${linkID}`
    const response = await axios.delete(url, { crossdomain: true })
    return response
  },
  deleteProductListing: async function (listingID) {
    const url = `/ProductListings/delete/${listingID}`
    const response = await axios.delete(url, { crossdomain: true })
    return response
  },
}
