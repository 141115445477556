import config from './config'
import Vue from 'vue'
export default {
  getResourceToken: async function () {
    const tokenRequest = {
      scopes: config.resourceScopes,
    }
    try {
      let token = localStorage.getItem('ResourceToken')
      if (token === null || token === 'null') {
        token = await Vue.prototype.$msal.acquireToken(tokenRequest)
        if (token.length > 20) {
          localStorage.setItem('ResourceToken', token.accessToken)
        }
      }
      return token.accessToken
    } catch (e) {
      // console.log('token request error ')
      // console.log(e)
      return null
    }
  },
}
