import axios from 'axios'
// import azuremsal from '../authentication/azuread-msal'

export default {
  getProductTypes: async function () {
    const response = await axios.get('/Masterdata/1', { crossdomain: true })
    return response.data
  },
  getDocumentTypes: async function () {
    const response = await axios.get('/Masterdata/2', { crossdomain: true })
    return response.data
  },
  getPartnerTypes: async function () {
    const response = await axios.get('/PartnerTypes', { crossdomain: true })
    return response.data
  },
  getCountryCodes: async function () {
    const response = await axios.get('/CountryCodes', { crossdomain: true })
    return response.data
  },
  getStateCodes: async function () {
    const response = await axios.get('/StateCodes', { crossdomain: true })
    return response.data
  },
  getStateCodsByCountry: async function (countryCode) {
    const response = await axios.get(`/StateCodes/${countryCode}`, { crossdomain: true })
    return response.data
  },
  getUnits: async function () {
    const response = await axios.get('/Units', { crossdomain: true })
    return response.data
  },
  getUnitById: async function (unitId) {
    const response = await axios.get('/Units/' + unitId, { crossdomain: true })
    return response.data
  },
  getStatusCodes: async function () {
    const response = await axios.get('/Masterdata/3', { crossdomain: true })
    return response.data
  },
  getDashboardTypes: async function () {
    const response = await axios.get('/Masterdata/4', { crossdomain: true })
    return response.data
  },
  getTransactionTypes: async function () {
    const response = await axios.get('/Masterdata/5', { crossdomain: true })
    return response.data
  },
}
