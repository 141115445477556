import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: () => import('@/views/pages/Index'),
      meta: {
        requireAuth: false,
      },
      children: [
        {
          name: 'Home',
          path: '',
          component: () => import('@/views/pages/Main'),
          meta: {
            requireAuth: false,
          },
        },
        {
          name: 'AppRegister',
          path: '/register',
          component: () => import('@/views/pages/RegisterUser'),
          meta: {
            requireAuth: false,
          },
        },
        {
          name: 'AppRegisterComplete',
          path: '/registerComplete',
          component: () => import('@/views/pages/RegisterComplete'),
          meta: {
            requireAuth: false,
          },
        },
        {
          name: 'MyProfile',
          path: '/myprofile',
          component: () => import('@/views/pages/Profile'),
          meta: {
            requireAuth: true,
          },
        },
        {
          name: 'MySetting',
          path: '/mysettings',
          component: () => import('@/views/pages/UserSettings'),
          meta: {
            requireAuth: true,
          },
        },
        {
          name: 'Signout',
          path: '/logout',
          component: () => import('@/views/pages/Logout'),
          meta: {
            requireAuth: true,
          },
        },
      ],
    },
    {
      path: '/dashboard',
      component: () => import('@/views/pages/Index'),
      meta: {
        requireAuth: true,
      },
      children: [
        {
          name: 'Dashboard',
          path: '',
          component: () => import('@/views/dashboard/Index'),
          meta: {
            requireAuth: true,
          },
        },
        {
          name: 'DashboardDetails',
          path: 'details/:type',
          props: true,
          component: () => import('@/views/dashboard/Details'),
          meta: {
            requireAuth: true,
          },
        },
      ],
    },
    {
      path: '/material',
      component: () => import('@/views/pages/Index'),
      meta: {
        requireAuth: true,
      },
      children: [
        {
          name: 'MaterialRecent',
          path: 'recent',
          component: () => import('@/views/material/Recent'),
          meta: {
            requireAuth: true,
          },
        },
        {
          name: 'MaterialHistory',
          path: 'history',
          component: () => import('@/views/material/History'),
          meta: {
            requireAuth: true,
          },
        },
        {
          name: 'MaterialMovement',
          path: 'movement/:type',
          props: true,
          component: () => import('@/views/material/Movement'),
          meta: {
            requireAuth: true,
          },
        },
        {
          name: 'MaterialMovementEdit',
          path: 'editmovement/:crtype/:transactionId',
          props: true,
          component: () => import('@/views/material/Movement'),
          meta: {
            requireAuth: true,
          },
        },
        {
          name: 'ReceiveWaste',
          path: 'receivewaste/:transactionId',
          props: true,
          component: () => import('@/views/material/ReceiveMovement'),
          meta: {
            requireAuth: true,
          },
        },
        {
          name: 'ReceiveProduct',
          path: 'receiveproduct/:transactionId',
          props: true,
          component: () => import('@/views/material/ReceiveMovement'),
          meta: {
            requireAuth: true,
          },
        },
        {
          name: 'CompleteRequest',
          path: 'completerequest/:transactionId',
          props: true,
          component: () => import('@/views/material/Movement'),
          meta: {
            requireAuth: true,
          },
        },
        {
          name: 'AcceptCommitment',
          path: 'acceptcommitment/:transactionId',
          props: true,
          component: () => import('@/views/material/ReceiveMovement'),
          meta: {
            requireAuth: true,
          },
        },
      ],
    },
    {
      path: '/settings',
      component: () => import('@/views/pages/Index'),
      meta: {
        requireAuth: true,
      },
      children: [
        {
          path: 'listings',
          name: 'ProductListings',
          component: () => import('@/views/settings/Listings'),
          meta: {
            requireAuth: true,
          },
        },
        {
          path: 'links',
          name: 'ProductLinks',
          component: () => import('@/views/pages/Links'),
          meta: {
            requireAuth: true,
          },
        },
        {
          path: 'listingDetails/:listingId',
          name: 'Add Listing',
          props: true,
          component: () => import('@/views/settings/ListingDetails'),
          meta: {
            requireAuth: true,
          },
        },
        {
          name: 'Product Detail',
          path: 'products/detail/:productId',
          props: true,
          component: () => import('@/views/pages/ProductDetail'),
          meta: {
            requireAuth: true,
          },
        },
        {
          name: 'Partner Detail',
          path: 'partners/detail/:partnerId',
          props: true,
          component: () => import('@/views/pages/PartnerDetail'),
          meta: {
            requireAuth: true,
          },
        },
      ],
    },
    {
      path: '/masterdata',
      component: () => import('@/views/pages/Index'),
      meta: {
        requireAuth: true,
      },
      children: [
        {
          name: 'ProductsManagement',
          path: 'products',
          component: () => import('@/views/masterdata/Products'),
          meta: {
            requireAuth: true,
          },
        },
        {
          path: 'ProductDetails/:productId',
          name: 'New Product',
          props: true,
          component: () => import('@/views/masterdata/ProductDetails'),
          meta: {
            requireAuth: true,
          },
        },
        {
          path: 'partners',
          name: 'PartnersManagement',
          component: () => import('@/views/masterdata/Partners'),
          meta: {
            requireAuth: true,
          },
        },
        {
          path: 'PartnerDetails/:partnerId',
          name: 'Edit Partner',
          props: true,
          component: () => import('@/views/masterdata/PartnerDetails'),
          meta: {
            requireAuth: true,
          },
        },
      ],
    },
    {
      path: '/admin',
      component: () => import('@/views/pages/Index'),
      meta: {
        requireAuth: true,
      },
      children: [
        {
          name: 'Users',
          path: 'users',
          component: () => import('@/views/admin/Users'),
          meta: {
            requireAuth: true,
          },
        },
        {
          name: 'Edit User',
          path: 'users/userdetails/:userId',
          props: true,
          component: () => import('@/views/admin/EditUser'),
          meta: {
            requireAuth: true,
          },
        },
      ],
    },
    {
      path: '*',
      component: () => import('@/views/pages/Index'),
      children: [
        {
          name: '404 Error',
          path: '',
          component: () => import('@/views/pages/Error'),
        },
      ],
    },
  ],
})

export default router
/*
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requireAuth)) {
    if (this.$msal.isAuthenticated()) {
      next()
      return
    }
    this.$msal.signIn()
  } else {
    next()
  }
})
*/
