import axios from 'axios'

export default {
  getDashboardData: async function () {
    const url = 'Dashboard'
    const response = await axios.get(url, { crossdomain: true })
    return response.data
  },
  getDashboardDetailsData: async function (partnerId) {
    const url = 'Dashboard/Details/' + partnerId
    const response = await axios.get(url, { crossdomain: true })
    return response.data
  },
  getDashboardCommitmentDetails: async function (type) {
    const url = 'Dashboard/Commitment/' + type + '/false'
    const response = await axios.get(url, { crossdomain: true })
    return response.data
  },
  getDashboardCommitmentDetailsAvailable: async function (type) {
    const url = 'Dashboard/Commitment/' + type + '/true'
    const response = await axios.get(url, { crossdomain: true })
    return response.data
  },
}
