import verifyAPI from '@/authentication/authorization'
export default {
  clientId: 'dc823759-43e5-44ef-a6e6-dcc497a48fd2',
  authority: 'https://login.microsoftonline.com/9b50c80f-103b-4433-a91c-b4b06c507387/',
  tenant: '9b50c80f-103b-4433-a91c-b4b06c507387',
  cacheLocation: 'localStorage',
  resource: 'https://eastmanchem.onmicrosoft.com/cechargebackapi',
  resourceScopes: ['https://eastmanchem.onmicrosoft.com/cechargebackapi/user_impersonation'],
  scopes: ['User.Read'], // 'User.Invite.All',
  graphendpoint: 'https://graph.microsoft.com/v1.0/me',
  configureMenu: function (currentUser) {
    const items = []
    const dashboardMenu = {
      icon: 'mdi-view-dashboard',
      title: 'listMenuDashboard',
      to: '/dashboard',
    }
    items.push(dashboardMenu)
    if (verifyAPI.isAuthorized(currentUser, 5)) {
      const materialMenu = {
        group: '/material',
        icon: 'mdi-chart-timeline-variant',
        title: 'listMenuMaterial',
        children: [
          {
            title: 'listMenuMaterialRecent',
            to: 'recent',
          },
          {
            title: 'listMenuMaterialHistory',
            to: 'history',
          },
        ],
      }
      items.push(materialMenu)
    }
    if (verifyAPI.isAuthorized(currentUser, 6)) {
      const settingMenu = {
        group: '/settings',
        icon: 'mdi-cog',
        title: 'listMenuSettings',
        children: [
          {
            title: 'listMenuMyProducts',
            to: 'listings',
          },
        ],
      }
      items.push(settingMenu)
    }
    if (verifyAPI.isAuthorized(currentUser, 7)) {
      const masterDataMenu = {
        group: '/masterdata',
        icon: 'mdi-database',
        title: 'listMenuMasterData',
        children: [
          {
            title: 'listMenuPartners',
            to: 'partners',
          },
          {
            title: 'listMenuProducts',
            to: 'products',
          },
        ],
      }
      items.push(masterDataMenu)
    }
    if (verifyAPI.isAuthorized(currentUser, 99)) {
      const adminMenu = {
        group: '/admin',
        icon: 'mdi-desktop-tower',
        title: 'listMenuSystemAdmin',
        children: [
          {
            title: 'listMenuUsers',
            to: 'users',
          },
        ],
      }
      items.push(adminMenu)
    }
    return items
  },
}
