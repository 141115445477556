<template>
  <base-material-card2
    :title="title"
    class="v-card--material-stats"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template v-slot:after-heading>
      <div class="ml-auto text-right">
        <h2 class="font-weight-light text--primary">
          {{ value }} <small>{{ smallValue }}</small>
        </h2>
      </div>
    </template>
  </base-material-card2>
</template>

<script>
  import Card from './Card'

  export default {
    name: 'MaterialStatsCard2',

    inheritAttrs: false,

    props: {
      ...Card.props,
      title: {
        type: String,
        default: undefined,
      },
      value: {
        type: String,
        default: undefined,
      },
      smallValue: {
        type: String,
        default: undefined,
      },
    },
  }
</script>

<style lang="sass">
.v-card--material-stats
  display: flex
  flex-wrap: wrap
  position: relative

  > div:first-child
    justify-content: space-between

  .v-card
    border-radius: 4px
    flex: 0 1 auto

  .v-card__text
    display: inline-block
    flex: 1 0 calc(100% - 120px)
    position: absolute
    top: 0
    right: 0
    width: 100%

  .v-card__actions
    flex: 1 0 100%
</style>
