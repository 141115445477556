import Vue from 'vue'
import Vuex from 'vuex'
import { getField, updateField } from 'vuex-map-fields'
import MasterDataAPI from './services/masterData'
import userAPI from '@/services/userServices'
import dashboardAPI from '@/services/dashboard'
import partnerAPI from '@/services/partnerServices'
import productAPI from '@/services/productServices'
import dataConfig from '@/authentication/config'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
    drawer: null,
    currentUser: null,
    partnerTypes: [],
    productTypes: [],
    documentTypes: [],
    transactionTypes: [],
    units: [],
    partners: [],
    productGroups: [],
    statusCodes: [],
    dashboardData: {
      wasteMaterialSent: [],
      wasteMaterialReceive: [],
      summary: [],
      materialShipped: [],
      materialReceive: [],
    },
    clientIPAddress: '',
    menuItems: [],
    dashboardTypes: [],
  },
  getters: {
    getField,
  },
  mutations: {
    updateField,
    SET_DRAWER (state, payload) {
      state.drawer = payload
    },
    updateCurrentUser (state, payload) {
      state.currentUser = payload
      // setup the menu
      state.menuItems = dataConfig.configureMenu(payload)
    },
    updatePartnerTypes (state, payload) {
      state.partnerTypes = payload
    },
    updateProductTypes (state, payload) {
      state.productTypes = payload
    },
    updateDocumentTypes (state, payload) {
      state.documentTypes = payload
    },
    updateTransactionTypes (state, payload) {
      state.transactionTypes = payload
    },
    updateDashboardData (state, payload) {
      state.dashboardData = payload
    },
    updateUnits (state, payload) {
      state.units = payload
    },
    updateStatusCodes (state, payload) {
      state.statusCodes = payload
    },
    updatePartners (state, payload) {
      state.partners = payload
    },
    updateProductGroups (state, payload) {
      state.productGroups = payload
    },
    updateClientIp (state, payload) {
      state.clientIPAddress = payload
    },
    updateDashboardTypes (state, payload) {
      state.dashboardTypes = payload
    },
  },
  actions: {
    async GET_CURRENT_USER (context, payload) {
      const response = await userAPI.getCurrentUser(payload)
      context.commit('updateCurrentUser', response.data)
    },
    async GET_PARTNER_TYPES (context) {
      const response = await MasterDataAPI.getPartnerTypes()
      context.commit('updatePartnerTypes', response.data)
    },
    async GET_PRODUCT_TYPES (context) {
      const response = await MasterDataAPI.getProductTypes()
      context.commit('updateProductTypes', response.data)
    },
    async GET_DOCUMENT_TYPES (context) {
      const response = await MasterDataAPI.getDocumentTypes()
      context.commit('updateDocumentTypes', response.data)
    },
    async GET_TRANSACTION_TYPES (context) {
      const response = await MasterDataAPI.getTransactionTypes()
      context.commit('updateTransactionTypes', response.data)
    },
    async GET_DASHBOARD_DATA (context) {
      const response = await dashboardAPI.getDashboardData()
      if (response.data !== null) {
        context.commit('updateDashboardData', response.data)
      }
    },
    async GET_UNITS (context) {
      const response = await MasterDataAPI.getUnits()
      context.commit('updateUnits', response.data)
    },
    async GET_STATUSCODES (context) {
      const response = await MasterDataAPI.getStatusCodes()
      context.commit('updateStatusCodes', response.data)
    },
    async GET_PARTNERS (context) {
      const response = await partnerAPI.getAllPartners()
      context.commit('updatePartners', response.data)
    },
    async GET_PRODUCT_GROUPS (context) {
      const response = await productAPI.getAllProducts()
      context.commit('updateProductGroups', response.data)
    },
    async GET_CLIENT_IP (context) {
      // if (process.env.VUE_APP_ENVIRONMENT !== 'Development-Local') {
      const response = await userAPI.getClientIp()
      context.commit('updateClientIp', response)
      // }
    },
    async GET_DASHBOARD_TYPES (context) {
      const response = await MasterDataAPI.getDashboardTypes()
      context.commit('updateDashboardTypes', response.data)
    },
  },
})
